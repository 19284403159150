import http from "@/http"

class Api {
  // 车辆分类
  async classification() {
    return await http.get(`/car/category`)
  }
  // 车辆类型
  async type() {
    return await http.get(`/car/vehicle_type`)
  }
  // 获取统计数据
  async statistics(params) {
    return await http.get('/car/chart', {params})
  }
}

export default new Api()