<template>
  <div class="container fullScreen car-wrap">
    <carForm @getStatistics="getStatistics"></carForm>
    <section class="section statistics-wrap">
      <ul class="list" :style="grid">
        <li class="item">
          <div class="value">
            <countTo :startVal="0" :endVal="total" :decimals="2" :duration="1500"></countTo>
          </div>
          <div class="label">车辆总数（辆）</div>
        </li>
        <li class="item">
          <div class="value">
            <countTo :startVal="0" :endVal="abnormal" :decimals="2" :duration="1500"></countTo>
          </div>
          <div class="label">异常车流量（辆/日）</div>
        </li>
        <li class="item">
          <div class="value">
            <countTo :startVal="0" :endVal="average" :decimals="2" :duration="1500"></countTo>
          </div>
          <div class="label">平均车流量（辆/日）</div>
        </li>
        <li class="item">
          <div class="value">
            <countTo :startVal="0" :endVal="peak" :decimals="2" :duration="1500"></countTo>
          </div>
          <div class="label">峰值车流量（辆）</div>
        </li>
        <li class="item">
          <div class="value">{{ peak_time }}</div>
          <div class="label">峰值车流量时间</div>
        </li>
      </ul>
    </section>
    <section class="section charts-wrap">
      <ve-line width="100%" height="100%" :data="chart.data"></ve-line>
    </section>
  </div>
</template>
<script>
import empty from "@/components/empty"
import carForm from "./form"
import countTo from "vue-count-to"
import VeLine from "v-charts/lib/line"
import config from "@/config"
export default {
  name: "car",
  components: {
    empty,
    VeLine,
    carForm,
    countTo
  },
  computed: {
    grid() {
      return this.isMobile ? 'grid-rows-gap: 20px;grid-template-columns: repeat(2, 1fr);grid-template-rows: repeat(3, 1fr);' : 'grid-template-columns: repeat(5, 1fr);grid-template-rows: repeat(1, 1fr);'
    }
  },
  data: () => ({
    ...config,
    abnormal: 0,
    average: 0,
    peak: 0,
    total: 0,
    peak_time: "暂无",
    chart: {
      data: {
        columns: ['日期', '异常车流量', '车辆总数'],
        rows: [],
      },
    }
  }),
  methods: {
    getStatistics(data) {
      if (data) {
        const {abnormal, average, peak, total, peak_time, series} = data
        this.abnormal = abnormal
        this.average = average
        this.peak = peak
        this.total = total
        this.peak_time = peak_time || '暂无'
        this.getChartData(series)
      } else {
        this.abnormal = 0
        this.average = 0
        this.peak = 0
        this.total = 0
        this.peak_time = '暂无'
        this.chart.data.rows = []
      }
    },
    getChartData(data) {
      this.chart.data.rows = data.map(item => {
        item['日期'] = item.time
        item['异常车流量'] = item.abnormal
        item['车辆总数'] = item.count
        return item
      })
    }
  },
  mounted() {
    document.title = '车流量报表'
  }
}
</script>
<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
}
.statistics-wrap {
  padding: 30px 20px;
  .list {
    display: grid;
    .item {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      box-sizing: border-box;
      &:not(:last-of-type):after {
        @include position($r: 0, $t: 50%);
        display: block;
        content: '';
        width: 1px;
        height: 20px;
        margin-top: -10px;
        background-color: #EEE;
      }
      .label {
        color: #AAA;
        font-size: 14px;
        margin-top: 10px;
      }
      .value {
        color: #333;
        font-size: 24px;
      }
    }
  }
}
.charts-wrap {
  flex: 1;
  min-height: 350px;
}
@media screen and (max-width: 768px) {
  .statistics-wrap {
    padding: 0;
    .list {
      .item {
        padding: 20px;
        border-bottom: 1px solid #f5f5f5;
        &:last-of-type {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 3;
          grid-row-end: 3;
        }
        &:after {
          display: none !important;
        }
      }
    }
  }
  .charts-wrap {
    flex: 0 0 500px;
  }
}
</style>