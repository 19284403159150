<template>
  <section class="section form-wrap">
    <el-form class="form" ref="form" size="mini" label-width="80px" label-position="right" :model="form.data">
      <el-row>
        <el-col :xl="4" :lg="24" :md="24" :sm="24">
          <el-form-item label-width="0px" style="text-align: center;">
            <tab :tabs="tabs" @handleTabClick="handleTabClick"></tab>
          </el-form-item>
        </el-col>
        <el-col :xl="4" :lg="6" :md="6" :sm="12">
          <el-form-item label="日期范围:">
            <el-date-picker style="width: 100%;" v-show="form.data.type === 'year'" clearable size="mini" type="year" align="right" placeholder="选择年份" format="yyyy" value-format="yyyy" v-model="form.data.date[0]" @change="submit"></el-date-picker>
            <el-date-picker style="width: 100%;" v-show="form.data.type === 'month'" clearable size="mini" type="month" align="right" placeholder="选择月份" format="yyyy-MM" value-format="yyyy-MM" v-model="form.data.date[0]" @change="submit"></el-date-picker>
            <el-date-picker style="width: 100%;" v-show="form.data.type === 'day'" clearable size="mini" type="date" align="right" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="form.data.date[0]" @change="submit"></el-date-picker>
            <template v-if="isMobile">
              <el-date-picker style="width: 100%;" v-show="form.data.type === 'custom'" clearable size="mini" align="right" type="date" format="yyyy-MM-dd hh:mm:ss" value-format="yyyy-MM-dd" placeholder="开始日期" v-model="form.data.date[0]" @change="submit"></el-date-picker>
              <el-date-picker style="width: 100%;" v-show="form.data.type === 'custom'" clearable size="mini" align="right" type="date" format="yyyy-MM-dd hh:mm:ss" value-format="yyyy-MM-dd" placeholder="结束日期" v-model="form.data.date[1]" @change="submit"></el-date-picker>
            </template>
            <template v-else>
              <el-date-picker style="width: 100%;" v-show="form.data.type === 'custom'" clearable size="mini" align="right" type="daterange" format="yyyy-MM-dd hh:mm:ss" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" v-model="form.data.date" @change="submit"></el-date-picker>
            </template>
          </el-form-item>
        </el-col>
        <el-col :xl="4" :lg="6" :md="6" :sm="12">
          <el-form-item label="门店:">
            <treeSelect v-model="form.data.park_name" @change="submit"></treeSelect>
          </el-form-item>
        </el-col>
        <el-col :xl="4" :lg="6" :md="6" :sm="12">
          <el-form-item label="车辆分类:">
            <el-select placeholder="请选择车辆分类" clearable filterable v-model="form.data.car_category" @change="submit">
              <el-option v-for="item in list.classification" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xl="4" :lg="6" :md="6" :sm="12">
          <el-form-item label="车辆类型:">
            <el-select placeholder="请选择车辆类型" clearable filterable v-model="form.data.vehicle_type" @change="submit">
              <el-option v-for="item in list.type" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
  import tab from "@/components/tab"
  import Api from "./api"
  import treeSelect from "@/components/treeSelect"
  import config from "@/config"
  import {message} from "@/utils"

  export default {
    name: "carForm",
    components: {
      tab,
      treeSelect
    },
    data: () => ({
      ...config,
      tabs: [
        {label: '日报表', value: 'day'},
        {label: '月报表', value: 'month'},
        {label: '年报表', value: 'year'},
        {label: '自定义报表', value: 'custom'},
      ],
      // 表单查询参数
      form: {
        data: {
          type: 'day',
          date: [''],
          phone: '',
          park_name: '',
          car_category: '',
          vehicle_type: '',
        }
      },
      // 下拉列表
      list: {
        garage: [],
        classification: [],
        type: []
      },
      // 地址栏参数
      params: {
        realname: '',
        phone: '',
        company: '',
        department: '',
        position: '',
        type: '',
        power: ''
      }
    }),
    methods: {
      initDate() {
        switch (this.form.data.type) {
          case 'year':
            this.form.data.date = [this.$moment().format('YYYY')]
            break;
          case 'month':
            this.form.data.date = [this.$moment().format('YYYY-MM')]
            break;
          case 'day':
            this.form.data.date = [this.$moment().format('YYYY-MM-DD')]
            break;
          case 'custom':
            this.form.data.date = ['', '']
            break;
        }
      },
      getCarType() {
        Api.type().then(res => {
          this.list.type = res.data
        }).catch(err => {
          console.log(err);
        })
      },
      getCarClassification() {
        Api.classification().then(res => {
          this.list.classification = res.data
        }).catch(err => {
          console.log(err);
        })
      },
      handleTabClick(data) {
        this.form.data.type = data
        this.initDate()
        this.form.data.type !== 'custom' && this.submit()
      },
      submit() {
        if (!this.form.data.date.includes('')) {
          if (this.form.data.type === 'custom' && this.$moment(this.form.data.date[0]).valueOf() > this.$moment(this.form.data.date[1]).valueOf()) {
            message('开始时间不得大于结束时间！', 'error')
            return false
          }
          Api.statistics({...this.form.data, date: this.form.data.date.join(',')}).then(res => {
            this.$emit('getStatistics', res.data)
          }).catch(err => {
            console.log(err);
          })
        }
      }
    },
    created() {
      this.params = this.$route.query
      this.initDate()
      this.getCarType()
      this.getCarClassification()
    }
  }
</script>

<style scoped lang="scss">
  .form-wrap {
    .form {
      .el-form-item,
      .el-select,
      .el-input {
        width: 100%;
      }
    }
  }
</style>